@font-face {
  font-family: 'FontC';
  src: local('FontC'), url("./files/Macondo-Regular.ttf") format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}
html * {
  font-family: 'FontC';
}

html, body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  min-height: -webkit-fill-available;
  width: 100vw;
  overflow: hidden;
}

#root {
  height: inherit;
  width: inherit;
}

